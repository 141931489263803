const base = require('base:login/login');
const flatpickr = require('flatpickr');
var formValidation = require('base:components/formValidation');

require('password-requirements/jquery.password-requirements-checker');
/**
 * Initialize plugins
 */
const init = () => {
    $(document).ready(function () {
        var minCustomerAge = $('.flatpickr').data('mincustomerage') || 16;
        $('.flatpickr').flatpickr({
            dateFormat: 'Y-m-d',
            wrap: true,
            maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - minCustomerAge))
        });

        $('#registration-form-password, #newPassword').passwordRequirements();
    });
}

base.login = function login() {
    $('form.login').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.login').trigger('login:submit', e);
        if (grecaptcha) {
            grecaptcha.ready(function() {
                grecaptcha.execute(window.app.preferences.reCaptchaSiteKey, { action: 'submit' }).then(function(token) {
                    var payload = form.serialize() + '&reCaptchaToken=' + token;
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: payload,
                        success: function (data) {
                            form.spinner().stop();
                            if (!data.success) {
                                formValidation(form, data);
                                $('form.login').trigger('login:error', data);
                            } else {
                                $('form.login').trigger('login:success', data);
                                location.href = data.redirectUrl;
                            }
                        },
                        error: function (data) {
                            if (typeof data.responseJSON !== 'undefined' && data.responseJSON.redirectUrl) {
                                window.location.href = data.responseJSON.redirectUrl;
                            } else {
                                $('form.login').trigger('login:error', data);
                                form.spinner().stop();
                            }
                        }
                    });
                });
            });
        }
        return false;
    });
};

base.register = function () {
    $('form.registration').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.registration').trigger('login:register', e);
        if (grecaptcha) {
            grecaptcha.ready(function() {
                grecaptcha.execute(window.app.preferences.reCaptchaSiteKey, { action: 'submit' }).then(function(token) {
                    var payload = form.serialize() + '&reCaptchaToken=' + token;
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: payload,
                        success: function (data) {
                            form.spinner().stop();
                            if (!data.success) {
                                $('form.registration').trigger('login:register:error', data);
                                formValidation(form, data);
                            } else {
                                $('form.registration').trigger('login:register:success', data);
                                location.href = data.redirectUrl;
                            }
                        },
                        error: function (err) {
                            if (err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            } else {
                                createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                            }
            
                            form.spinner().stop();
                        }
                    });
                });
            });
        }
        return false;
    });
},

module.exports = {
    ...base,
    ...{ init: init }
};
